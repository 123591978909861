import { useState } from "react";
import "./selectTeams.css";
const SelectTeam = (props) => {
  const [selectedTeam, setSelectedTeam] = useState("");
  function selectHandler(value) {
    setSelectedTeam(value);
    props.onSelect(value);
  }
  return (
    <div className="selectTeamContainer">
      <div className="rowContainer">
        <div className="team1" onClick={() => selectHandler("1")} />
        <div className="team2" onClick={() => selectHandler("2")} />
        <div className="team3" onClick={() => selectHandler("3")} />
      </div>

      <div className="rowContainer">
        <div className="team4" onClick={() => selectHandler("4")} />
        <div className="team5" onClick={() => selectHandler("5")} />
      </div>
    </div>
  );
};
export default SelectTeam;
