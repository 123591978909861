import { useEffect, useState } from "react";
import classes from '../screens/game/game-styles.module.css'
const Timer = (props) => {
    const [timer, setTimer] = useState(30)
    let timeout
    const { chose } = props
    useEffect(() => {
        if (timer >= 0 && !chose) {
            timeout = setTimeout(() => setTimer(timer - 1), 1000);
        }
        else if (chose) {
            setTimer(30)
            clearTimeout(timeout)
        }
        else {
            props.nextQuestion()
            setTimer(30)
            return (
                clearTimeout(timeout)
            )
        }
    }, [timer, chose]);

    return (
        <div className={classes.counter}>
            <h6 className={classes.timer}>00:{timer}</h6>
        </div>
    )
}
export default Timer;