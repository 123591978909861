export const questions = [
  {
    type: "compliance",
    lang: "ar",
    question: `.... is the functional unit of the kidney, within which blood is filtered and substances reabsorbed and secreted`,
    options: [
      {
        option: "Proximal Convoluted tubule",
        valid: false,
      },
      {
        option: "Nephron",
        valid: true,
      },
      {
        option: "Glomerulus",
        valid: false,
      },
      {
        option: "Bowman’s capsule",
        valid: false,
      },
    ],
  },
  {
    type: "compliance",
    lang: "en",
    question:
      "Glomerular filtration rate and blood pressure are regulated by the ……………",
    options: [
      {
        option: "Tubuloglomerular feedback",
        valid: false,
      },
      {
        option: "Renin–angiotensin–aldosterone system- RAAS",
        valid: false,
      },
      {
        option: "A & B",
        valid: true,
      },
    ],
  },
  {
    type: "compliance",
    lang: "ar",
    question: "…….. Is the significant cause of Chronic Kidney Disease",
    options: [
      {
        option: "Hypertension",
        valid: false,
      },
      {
        option: "Glomerulonephritis",
        valid: false,
      },
      {
        option: "Diabetes",
        valid: true,
      },
      {
        option: "Heart Failure",
        valid: false,
      },
    ],
  },
  {
    type: "compliance",
    lang: "en",
    question:
      "……………..plays a crucial pathogenic role in the loss of kidney function also serves as a marker of kidney damage ",
    options: [
      {
        option: "Proteinuria",
        valid: true,
      },
      {
        option: "Natriuretic peptide",
        valid: false,
      },
      {
        option: "Troponin I &T",
        valid: false,
      },
      {
        option: "C-reactive protein",
        valid: false,
      },
    ],
  },
  {
    type: "compliance",
    lang: "ar",
    question: `The three main causes of AKI are reduced blood flow, direct kidney damage, and urinary tract blockage`,
    options: [
      {
        option: "TRUE",
        valid: true,
      },
      {
        option: "FALSE",
        valid: false,
      },
    ],
  },
  {
    type: "compliance",
    lang: "en",
    question:
      "CKD is implicated in the pathophysiology of anemia, hyperkalemia, and liver cirrhosis",
    options: [
      {
        option: "TRUE",
        valid: false,
      },
      {
        option: "FALSE",
        valid: true,
      },
    ],
  },
];
