import { useState } from "react"
import classes from '../screens/game/game-styles.module.css'
import Right from "../screens/game/right";
import Wrong from "../screens/game/wrong";
let clickedOnce = false;
let alpha =['a','b','c','d','d']
const Option = (props) => {
    const [isClicked, setIsClicked] = useState(false)
    const { currentQuestion, option, chose, isRightAnswer, clicked } = props;
    const [optionClicked, setOptionClicked] = useState(false);
    function rightOrWrongHandler(valid) {
        if (valid) {
            return (
                <Right />
            )
        }
        else if (!isRightAnswer) {
            return (
                <Wrong />
            )
        }
    }
    return (
        <div className={classes.optionButton} onClick={() => {
            setIsClicked(true)
            console.log(isClicked);

            if (!clickedOnce) { // check for first time
                setOptionClicked(true)
                props.onClick()
                clickedOnce = true // stop user from clicking on the right answer when it's revealed after the user chose his answer
                setTimeout(() => {
                    clickedOnce = false // sets it again to false to enable user to choose answers again when next question appears
                }, 1000)
            }
        }}>
            <div  style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <div>
                    <h1 className={currentQuestion.type === 'compliance' ? classes.optionCompliance : classes.optionFriends}
                    > {alpha[props.num]} - {option.option}</h1>
                </div>
                <div style={{ width: '60px',marginLeft:'15px' }}>
                    {chose && rightOrWrongHandler(option.valid)}
                </div>
            </div>
        </div>)
}
export default Option;