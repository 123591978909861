import { useReducer } from 'react'
import ScoreContext from "./score-context";
const defaultScoreState = {
    score:0
}

const scoreReducer = (state, action) => {
    // console.log(action);

    if (action.type == 'ADD') {
        let temp = state.score + 1;
        return {
            score: temp
        };
    }
    else if (action.type == 'RESET') {
        let temp = 0;
        return {
            score: 0
        };
    }

}

const ScoreProvider = props => {

    const [scoreState, dispachScoreAction] = useReducer(scoreReducer, defaultScoreState)
    const add = () => {
        dispachScoreAction({ type: 'ADD' })
    }
    const resetScore = () => {
        dispachScoreAction({ type: 'RESET' })
    }
    const scoreContext = {
        score: scoreState.score,
        add: add,
        resetScore: resetScore
    }
    return (<ScoreContext.Provider value={scoreContext}>
        {props.children}
    </ScoreContext.Provider>)
}

export default ScoreProvider;