import "./App.css";
import Game from "./screens/game/game";
import React, { useEffect, useState } from "react";
import ScoreProvider from "./store/score-provider";
import SelectTeam from "./screens/selectTeam/selectTeam";

function App() {
  const [gameFinished, setGameFinished] = useState(false);
  const [startGame, setStartGame] = useState(false);
  const [teamSelected, setTeamSelected] = useState(false);
  const [isPortrait, setIsPortrait] = useState(true);

  async function gameFinishedHandler(finished, rightAnswers) {
    if (finished) {
      setGameFinished(true);
      setTeamSelected(false);
      window.location.reload();
    }
  }

  let allContent = isPortrait ? (
    <div className="background-image">
      <div className="mainLogo" />
      <div className="deck" />
      <div className="board">
        {!teamSelected && (
          <SelectTeam
            onSelect={(team) => {
              setTeamSelected(team);
              setStartGame(true);
              setGameFinished(false);
            }}
          />
        )}
        {!gameFinished && startGame && (
          <Game team={teamSelected} onFinish={gameFinishedHandler} />
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
      }}
    >
      <h1 style={{ color: "white", fontSize: "50px" }}>
        Please use portrait mode
      </h1>
    </div>
  );

  useEffect(() => {
    if (
      window.innerWidth > window.innerHeight &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setIsPortrait(false);
    } else {
      setIsPortrait(true);
    }
  }, [window.innerWidth]);

  return (
    <ScoreProvider>
      <div className="background-image">
        {allContent}
        <div className="landscape">
          <h1 style={{ color: "white", fontSize: "50px", alignSelf: "center" }}>
            Please use portrait mode
          </h1>
        </div>
      </div>
    </ScoreProvider>
  );
}
export default App;
