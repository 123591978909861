import React, { useContext, useEffect, useState } from "react";
import Option from "../../components/option";
import Timer from "../../components/timer";
import { questions } from "../../questions-array/questions";
import ScoreContext from "../../store/score-context";
import Finished from "./finished-message";
import classes from "./game-styles.module.css";
import { collection } from "../../firebase/firebaseconfig";

const Game = (props) => {
  const scoreCtx = useContext(ScoreContext);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isRightAnswer, setRightAnswer] = useState(false); //indicates that we answered the right answer
  const [chose, setChose] = useState(false);
  const [showFinishMessage, setShowFinishMessage] = useState(false);
  const [closeFinishMessage, setCloseFinishMessage] = useState(false);
  const [elnazerRightAnswers, setElnazerRightAnswers] = useState(0);
  const [complianceRightAnswers, setComplianceRightAnswers] = useState(0);

  const setScore = async (score = 0) => {
    const teamSelected = props.team;
    const teamRef = collection.doc("teams");
    const teams = await teamRef.get();
    let teamsData = teams.data();
    if (teamsData[`t${teamSelected}`] === undefined) {
      teamsData[`t${teamSelected}`] = { g1: [] };
    } else if (teamsData[`t${teamSelected}`].g1 === undefined) {
      teamsData[`t${teamSelected}`].g1 = [];
    }
    teamsData[`t${teamSelected}`].g1 = [
      ...teamsData[`t${teamSelected}`].g1,
      {
        score: score * 100,
        timestamp: Date.now(),
      },
    ];
    await teamRef.set(teamsData);
  };

  function nextQuestionHandler(score) {
    console.log("score", score);
    setTimeout(() => {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setScore(score);
        setShowFinishMessage(true);
      }
      setChose(false);
    }, 1000);
  }

  useEffect(() => {
    if (closeFinishMessage && showFinishMessage) {
      props.onFinish(true, complianceRightAnswers);
      scoreCtx.resetScore();
    }
  }, [showFinishMessage, closeFinishMessage]);
  function scoreHandler(valid) {
    if (valid) {
      // if (questions[currentQuestion].type === 'compliance') {
      //     setComplianceRightAnswers(complianceRightAnswers + 1)
      //     scoreCtx.increaseCompliance()
      // }
      // else if (questions[currentQuestion].type === 'nazer') {
      //     setElnazerRightAnswers(elnazerRightAnswers + 1)
      //     scoreCtx.increaseNazer()
      // }
      scoreCtx.add();
      setRightAnswer(true); // indicates that we answered the right answer
      setComplianceRightAnswers(complianceRightAnswers + 1);
    } else {
      setRightAnswer(false);
    }
    nextQuestionHandler(valid ? scoreCtx.score + 1 : scoreCtx.score);
  }
  function Options() {
    return questions[currentQuestion].options.map((value, index) => {
      let clicked = false;
      return (
        <Option
          num={index}
          isRightAnswer={isRightAnswer}
          key={index}
          clicked={clicked}
          option={value}
          chose={chose}
          currentQuestion={questions[currentQuestion]}
          onClick={() => {
            clicked = true;
            setChose(true);
            scoreHandler(value.valid);
          }}
        />
      );
    });
  }
  return (
    <div className={classes.gameContainer}>
      {showFinishMessage && (
        <Finished
          onClose={() => {
            setCloseFinishMessage(true);
          }}
        />
      )}
      {!showFinishMessage && (
        <div>
          <div className={classes.timerContainer}>
            <Timer
              chose={chose}
              nextQuestion={() => {
                setChose(true);
                scoreHandler(false);
              }}
            />
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={classes.questionsAndOptionsContainer}>
              <p
                className={classes.question}
                style={
                  questions[currentQuestion].type == "compliance"
                    ? { fontFamily: "Eraser", fontWeight: "bold" }
                    : { fontFamily: "friends" }
                }
              >
                {questions[currentQuestion].question}
              </p>
              <div className={classes.optionsContainer}>
                <Options />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Game;
