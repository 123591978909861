import classes from './finished.module.css'
const Finished = (props) => {
    function backToTeamsHandler(){
        props.onClose()
    }
    return (<div className={classes.finishedContainer}>

        <div className={classes.finishedMessage}>
            <div onClick={()=>backToTeamsHandler()} className={classes.closeButton}/>
        </div>
    </div>)
}
export default Finished